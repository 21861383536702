<template>
    <div
    class="
      d-flex
      flex-column flex-column-fluid
      bgi-position-y-bottom
      position-x-center
      bgi-no-repeat bgi-size-contain bgi-attachment-fixed
    "
  >
    <!--begin::Content-->
    <div class="d-flex flex-center flex-column flex-column-fluid p-25">
      
      <div class="card" style="width: 100%; max-width: 1700px;">
        <div class="card-body position-relative">
          <signatures />
        </div>
      </div>

    </div>
    <!--end::Content-->


    <!--end::Footer-->
  </div>

</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from "vue";
import signatures from "@/views/still/bord/signatures.vue";

import mAxiosApi from "@/api";

export default defineComponent({
  name: "Overview",
  components: {
    signatures
  },
  setup() {
    const state = reactive({
      loaderEnabled: true,
      myPDF : "" as any,
    });

    onMounted(async () => {
      //
    });

    return {
      state,
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
</script>
<style>

</style>